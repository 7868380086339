document.addEventListener("DOMContentLoaded", function () {
    const sections = document.querySelectorAll('.user-items-list[data-space-below-section-title-value="173"]');

    // Only run the code if the sections exist
    if (sections.length > 0) {
        const canHover = window.matchMedia('(hover: hover)').matches;

        sections.forEach((section) => {
            const pageSection = section.closest('section');
            pageSection.classList.add('list-hover-layout');
            const list = section.querySelector('ul');

            const items = list.querySelectorAll('.list-item');
            items.forEach((item) => {
                const button = item.querySelector('.list-item-content__button');
                if (button) {
                    // Remove button from the tab order
                    button.setAttribute('tabindex', '-1');

                    if (button.href && button.href.trim() !== "" && button.getAttribute('href') !== "" && button.href !== "#") {
                        // If the button has a valid href, wrap in an anchor
                        const link = document.createElement('a');
                        link.className = 'item-link';
                        link.href = button.href;
                        item.parentNode.insertBefore(link, item);
                        link.appendChild(item);
                    } else {
                        // If the button doesn't have a valid href, wrap in a div
                        const wrapper = document.createElement('div');
                        wrapper.className = 'item-link';
                        item.parentNode.insertBefore(wrapper, item);
                        wrapper.appendChild(item);
                    }
                }
            });

            if (canHover) {
                pageSection.classList.add('can-hover');
                // Track mouse position if the device supports hover
                list.addEventListener("mousemove", (e) => {
                    const rect = list.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;
                    list.style.setProperty('--mouse-x', `${x}px`);
                    list.style.setProperty('--mouse-y', `${y}px`);
                });
            } else {
                const listHeight = section.querySelector('.user-items-list-simple').offsetHeight;
                const halfHeight = listHeight / 2;
                section.style.setProperty('--imgmaxheight', `${halfHeight}px`);
                // Add active class when an item-link is at the middle of the screen
                const observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            const target = entry.target;
                            if (entry.isIntersecting) {
                                target.classList.add('active');
                            } else {
                                target.classList.remove('active');
                            }
                        });
                    },
                    {
                        root: null, // Use the viewport as the root
                        rootMargin: '-50% 0px -50% 0px',
                        threshold: 0 // Trigger as soon as it enters this region
                    }
                );

                const itemLinks = section.querySelectorAll('.item-link');
                itemLinks.forEach((link) => observer.observe(link));
            }
        });
    }
});
